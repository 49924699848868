import React from 'react';

import './Loading.css';

function Loading() {
    return (
        <div className='loading'></div>
    );
}

export default Loading;